import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

const Countdown = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });

    function calculateTimeLeft() {
        let year = new Date().getFullYear();
        const difference = +new Date(`${year}-12-31`) - +new Date();
        
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        
        return timeLeft;
    }

    return (
        <>
            <Text fontWeight={'625'} marginTop={[2,4]} fontSize={['2xl', '3xl']}>
                {timeLeft.days} days, {timeLeft.hours} hours, {timeLeft.minutes} minutes, and {timeLeft.seconds} seconds until your company's education stipend resets.
            </Text>
        </>
    );
};

export default Countdown;