export const productData = [
  {
    title: 'Skillshare 1-Year',
    price: 168,
    link: 'https://www.amazon.com',
    category: 'Platform',
    image: 'skillshare.png',
    numBought: 11,
    content:
      "No surprise, Skillshare has TONS of courses on product. \n While you're becoming a more effective PM, you can use the same plan to get better at cooking/photography/other things in life that you care about.",
  },
  {
    title: 'Coursera Plus 1-Year',
    price: 399,
    link: 'https://imp.i384100.net/AWKNrx',
    category: 'Platform',
    image: 'courserapm.png',
    numBought: 10,
    content:
      "Similar to Skillshare: Coursera has tons of high-quality PM courses, but the classes are more involved and can span weeks. Because of this, it's more $$$, and they come with more robust certifications, if you care about that sorta thing. \n Like Skillshare, you can use the same plan to get better at non-product-related life endeavors like film-making or baking.",
  },
  {
    title: 'Inspired: How to Create Tech Products Customers Love',
    price: 26.72,
    link: 'https://amzn.to/43piNcr',
    category: 'Book',
    image: 'inspired.jpg',
    numBought: 9,
    content:
      "by Marty Cagan. \n It's in the name. \n Description: How do today’s most successful tech companies―Amazon, Google, Facebook, Netflix, Tesla―design, develop, and deploy the products that have earned the love of literally billions of people around the world? Perhaps surprisingly, they do it very differently than most tech companies. In INSPIRED, technology product management thought leader Marty Cagan provides readers with a master class in how to structure and staff a vibrant and successful product organization, and how to discover and deliver technology products that your customers will love―and that will work for your business.",
  },
  {
    title: 'The Mom Test',
    price: 18.12,
    link: 'https://amzn.to/3NbClLY',
    category: 'Book',
    image: 'momtest.jpg',
    numBought: 12,
    content:
      "by Rob Fitzpatrick. \n Get the honest answer from Mom. \n Description: They say you shouldn't ask your mom whether your business is a good idea, because she loves you and will lie to you. This is technically true, but it misses the point. You shouldn't ask anyone if your business is a good idea. It's a bad question and everyone will lie to you at least a little . As a matter of fact, it's not their responsibility to tell you the truth. It's your responsibility to find it and it's worth doing right. Talking to customers is one of the foundational skills of both Customer Development and Lean Startup. We all know we're supposed to do it, but nobody seems willing to admit that it's easy to screw up and hard to do right. This book is going to show you how customer conversations go wrong and how you can do better.",
  },
  {
    title: 'Swipe to Unlock: The Primer on Technology and Business Strategy',
    price: 26.79,
    link: 'https://amzn.to/3oM0MpI',
    category: 'Book',
    image: 'swipetounlock.jpg',
    numBought: 11,
    content:
      'Description: Authored by 3 Product Managers at Google, Facebook, and Microsoft, Swipe to Unlock is a comprehensive guide on the must-know concepts of technology and business strategy. It is a must-read for anyone pursuing product management, design, marketing, consulting or business strategy roles in the tech industry.',
  },
  {
    title: 'Measure What Matters',
    price: 15.49,
    link: 'https://amzn.to/48jSaaZ',
    category: 'Book',
    image: 'measurebook.jpg',
    numBought: 11,
    content:
      'by John Doerr \n Description: Legendary venture capitalist John Doerr reveals how the goal-setting system of Objectives and Key Results (OKRs) has helped tech giants from Intel to Google achieve explosive growth—and how it can help any organization thrive.',
  },
  {
    title: 'Everybody Lies',
    price: 20.94,
    link: 'https://amzn.to/3oVzaOW',
    category: 'Book',
    image: 'everybodylies.jpg',
    numBought: 5,
    content:
      'by Seth Stephens-Davidowitz  \n Description: Everybody Lies offers fascinating, surprising, and sometimes laugh-out-loud insights into everything from economics to ethics to sports to race to sex, gender and more, all drawn from the world of big data. What percentage of white voters didn’t vote for Barack Obama because he’s black? Does where you go to school effect how successful you are in life? Do parents secretly favor boy children over girls? Do violent films affect the crime rate? Can you beat the stock market? How regularly do we lie about our sex lives and who’s more self-conscious about sex, men or women?',
  },
  {
    title: 'The Lean Startup',
    content:
      'As opposed to a fat startup? \n Description: The Lean Startup approach fosters companies that are both more capital efficient and that leverage human creativity more effectively. Inspired by lessons from lean manufacturing, it relies on “validated learning,” rapid scientific experimentation, as well as a number of counter-intuitive practices that shorten product development cycles, measure actual progress without resorting to vanity metrics, and learn what customers really want. It enables a company to shift directions with agility, altering plans inch by inch, minute by minute.',
    price: '18.93',
    link: 'https://amzn.to/3qieT6u',
    category: 'Book',
    image: 'lean.jpg',
    numBought: 14,
  },
];