import React, {useState} from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Collapse,
  HStack,
  Spacer,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Block from './components/Block';
import Countdown from './components/Countdown';
import {sweData} from './data/sweData.ts';
import { productData } from './data/productData.ts';
import { designData } from './data/designData.ts';
import { leadershipData } from './data/leadershipData.ts';
import { salesData } from './data/salesData.ts';
import CustomFont from './Montserrat.ttf';
import theme from "./theme.ts";
import {Footer} from './components/Footer.jsx'


function App() {

  const fontFaceStyles = `
  @font-face {
    font-family: 'CustomFont';
    src: url(${CustomFont}) format('truetype');
    /* You may need to include different src properties for different browser compatibility */
  }
`;

  const [selectedTab, setSelectedTab] = useState("Software Engineering");
  const renderTabContent = () => {
    var dataArr = [];
    switch (selectedTab) {
      case "Software Engineering":
        dataArr = sweData;
        break;
      case "Design":
        dataArr = designData;
        break;
      case "Product":
        dataArr = productData;
        break;
      case "Sales":
        dataArr = salesData;
        break;
      case "Leadership":
        dataArr = leadershipData;
        break;
      default:
        dataArr = sweData;
    }
    return dataArr.map((blockData, index) => (
      <Block
          key={index}
          title={blockData.title}
          content={blockData.content}
          price={blockData.price}
          link={blockData.link}
          category={blockData.category}
          image={blockData.image}
          numBought={blockData.numBought}
          order={index===0 ? 'first' : index===dataArr.length-1 ? 'last' : 'middle'}
      />
  ))
  };
  

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" >
      <HStack padding={3.5}>
        <Text fontSize='2xl'>💸 stipends.fyi</Text>
        <Spacer />
        <ColorModeSwitcher justifySelf="flex-end" />
      </HStack>
        <Grid minH="100vh" p={0} w='100vw'>
          <VStack spacing={6} w='100vw'>
            <Box w='95vw'>
              <Countdown />
              {/* <Text marginTop={[2, 4]} fontSize={['lg',  'xl']} >Get cool shit for free before it does. 106 employees have already expensed $11,239 worth of stuff through stipends.fyi</Text> */}
              <Text marginTop={[2, 4]} fontSize={['lg',  'xl']} >Join 49 employees who have already expensed $12,806 of cool stuff on stipends.fyi</Text>
              <Text marginTop={[2, 4]} opacity={'60%'} fontSize='sm'>Note: some of these links have an affiliate kickback, 50% of which will go to St. Jude's Children's Hospital.</Text>
            </Box>
            <HStack justifyContent={["left", "center"]} w='100vw' overflowX='scroll' spacing={2}>
              <Button marginLeft={1} flexShrink={0} colorScheme = {selectedTab === "Software Engineering" ? 'blue' : 'gray' } onClick={() => setSelectedTab("Software Engineering")}>🤖 SWE</Button>
              <Button flexShrink={0} colorScheme = {selectedTab === "Product" ? 'blue' : 'gray' } onClick={() => setSelectedTab("Product")}>🚢 Product </Button>
              <Button flexShrink={0} colorScheme = {selectedTab === "Design" ? 'blue' : 'gray' } onClick={() => setSelectedTab("Design")}>🎨 Design</Button>
              {/* <Button flexShrink={0} colorScheme = {selectedTab === "Sales" ? 'blue' : 'gray' } onClick={() => setSelectedTab("Sales")}>💰 GTM</Button> */}
              <Button flexShrink={0} colorScheme = {selectedTab === "Leadership" ? 'blue' : 'gray' } onClick={() => setSelectedTab("Leadership")}>🤝 Leadership</Button>
            </HStack>
            <VStack spacing={[0,0]} w='100vw' paddingBottom={[5,10]}>
            {/* {sweData.map((blockData, index) => (
                <Block
                    key={index}
                    title={blockData.title}
                    content={blockData.content}
                    price={blockData.price}
                    link={blockData.link}
                    category={blockData.category}
                />
            ))} */}
                      {renderTabContent()}
            </VStack>
          </VStack>
        </Grid>
      </Box>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
