export const salesData = [
  {
    title: 'Skillshare 1-Year Membership',
    content:
      "No surprise, Skillshare has TONS of courses on sales and marketing. \n While you're becoming a more effective GTM beast, you can use the same plan to get better at cooking/photography/other things in life that you care about.",
    price: '168',
    link: 'https://www.amazon.com/',
    category: 'Platform',
  },
  {
    title: 'Coursera 1-Year Membership',
    content:
      "Similar to Skillshare: Coursera has tons of high-quality courses across GTM, but the classes are more involved and can span weeks. Because of this, it's more $$$, and they come with more robust certifications, if you care about that sort of thing. \n Like Skillshare, you can use the same plan to get better at non-sales-related life endeavors like film-making or baking.",
    price: '399',
    link: 'https://imp.i384100.net/PyanEN',
    category: 'Platform',
  },
  {
    title: "O'Reilly Online Learning 1-Year Membership",
    content: 'Test text',
    price: '499',
    link: 'https://www.amazon.com/',
    category: 'Platform',
  },
  {
    title: 'Codeacademy 1-Year Membership',
    content: 'Test text',
    price: '359.88',
    link: 'https://www.amazon.com/',
    category: 'Platform',
  },
  {
    title: 'Codeacademy 1-Year Membership',
    content: 'Test text',
    price: '359.88',
    link: 'https://www.amazon.com/',
    category: 'Platform',
  },
  {
    title: 'Codeacademy 1-Year Membership',
    content: 'Test text',
    price: '359.88',
    link: 'https://www.amazon.com/',
    category: 'Book',
  },
];