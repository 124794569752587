export const designData = [
  {
    title: 'Skillshare 1-Year',
    price: 168,
    link: 'https://skillshare.eqcm.net/c/4273707/298081/4650',
    category: 'Platform',
    image: 'skillshare.png',
    numBought: 11,
    content:
      "No surprise, Skillshare has TONS of courses on design. \n While you're becoming a more effective Figma power-user, you can use the same plan to get better at cooking/photography/other things in life that you care about.",
  },
  {
    title: 'Coursera Plus 1-Year',
    price: 399,
    link: 'https://imp.i384100.net/AWKNrx',
    category: 'Platform',
    image: 'courseradesign.png',
    numBought: 10,
    content:
      "Similar to Skillshare: Coursera has tons of high-quality design courses, but the classes are more involved and can span weeks. Because of this, it's more $$$, and they come with more robust certifications, if you care about that sorta thing. \n Like Skillshare, you can use the same plan to get better at non-Figma-related life endeavors like film-making or baking.",
  },
  {
    title: 'Designing Design',
    price: 34.73,
    link: 'https://amzn.to/43eYxug',
    category: 'Book',
    image: 'designingdesign.jpg',
    numBought: 4,
    content:
      "by Kenya Hara. \n Described by design peers as a 'flex of a coffee table book to own \n Description: Representing a new generation of designers in Japan, Kenya Hara (born 1958) pays tribute to his mentors, using long overlooked Japanese icons and images in much of his work. In Designing Design, he impresses upon the reader the importance of emptiness in both the visual and philosophical traditions of Japan, and its application to design, made visible by means of numerous examples from his own work: Hara for instance designed the opening and closing ceremony programs for the Nagano Winter Olympic Games 1998. In 2001, he enrolled as a board member for the Japanese label MUJI and has considerably moulded the identity of this successful corporation as communication and design advisor ever since. Kenya Hara, alongside Naoto Fukasawa one of the leading design personalities in Japan, has also called attention to himself with exhibitions such as Re-Design: The Daily Products of the 21st Century.'",
  },
  {
    title: 'The Shape of Design',
    price: 17.95,
    link: 'https://amzn.to/43gQYmM',
    category: 'Book',
    image: 'mismatch.jpg',
    numBought: 4,
    content:
      'by Kat Holmes. \n Description: Representing a new generation of designers in Japan, Kenya Hara (born 1958) pays tribute to his mentors, using long overlooked Japanese icons and images in much of his work. In Designing Design, he impresses upon the reader the importance of emptiness in both the visual and philosophical traditions of Japan, and its application to design, made visible by means of numerous examples from his own work: Hara for instance designed the opening and closing ceremony programs for the Nagano Winter Olympic Games 1998. In 2001, he enrolled as a board member for the Japanese label MUJI and has considerably moulded the identity of this successful corporation as communication and design advisor ever since. Kenya Hara, alongside Naoto Fukasawa one of the leading design personalities in Japan, has also called attention to himself with exhibitions such as Re-Design: The Daily Products of the 21st Century.',
  },
  {
    title: 'The Timeless Way of Building',
    price: 42.86,
    link: 'https://amzn.to/3OQ2qRU',
    category: 'Book',
    image: 'timeless.jpg',
    numBought: 3,
    content:
      'by Christopher Alexander. \n Alexander writes, "There is one timeless way of building. It is thousands of years old, and the same today as it has always been. The great traditional buildings of the past, the villages and tents and temples in which man feels at home, have always been made by people who were very close to the center of this way. And as you will see, this way will lead anyone who looks for it to buildings which are themselves as ancient in their form as the trees and hills, and as our faces are."',
  },
  {
    title: 'Patterns: Inside the Design Library',
    price: 75,
    link: 'https://amzn.to/3IMfkwo',
    category: 'Book',
    image: 'patterns.jpg',
    numBought: 1,
    content:
      'by Peter Koepke (Author), Julia Hasting (Designer). \n Description: An insider\'s guide to the world\'s largest archive of patterns and textiles, the source of inspiration for the globe\'s top designers',
  },
  {
    title: 'The Beauty of Everyday Things',
    price: 15.2,
    link: 'https://amzn.to/3oO2bMq',
    category: 'Book',
    image: 'beautyeveryday.jpg',
    numBought: 4,
    content: "by Soetsu Yanagi. \n It's in the name. \nDescription: The Japanese philosopher and aesthete's definitive, hugely influential exposition of his philosophy of folkcrafts, setting out the hallmarks of Japanese design as we know it today: anonymity, quality, simplicity and honesty—and, of course, wabi-sabi, the beauty of imperfection",
  },
  {
    title: 'How to Use Graphic Design to',
    price: 33.05,
    link: 'https://amzn.to/3C8JhTL',
    category: 'Book',
    image: 'howtodesign.jpg',
    numBought: 6,
    content:
      'by Michael Bierut. \n ...to Sell Things, Explain Things, Make Things Look Better, Make People Laugh, Make People Cry, and (Every Once in a While) Change the World',
  },
  {
    title: 'The Visual History of Type',
    price: 85,
    link: 'https://amzn.to/3WGiJTl',
    category: 'Book',
    image: 'typehistory.jpg',
    numBought: 6,
    content: 'by Paul McNeil. \n Pretty letters. \nDescription: The Visual History of Type is a comprehensive, detailed survey of the major typefaces produced since the advent of printing with movable type in the mid–fifteenth century to the present day. Arranged chronologically to provide context, more than 320 typefaces are displayed in the form of their original type specimens or earliest printing. Each entry is supported by a brief history and description of key characteristics of the typeface.',
  },
  {
    title: 'In Praise of Shadows',
    price: 9.95,
    link: 'https://amzn.to/3C6Tiki',
    category: 'Book',
    image: 'shadows.jpg',
    numBought: 3,
    content: 'by Junichiro Tanizaki. \n Description: An essay on aesthetics by the Japanese novelist, this book explores architecture, jade, food, and even toilets, combining an acute sense of the use of space in buildings. The book also includes descriptions of laquerware under candlelight and women in the darkness of the house of pleasure.',
  },
  {
    title: 'Design as Art ',
    price: 15.99,
    link: 'https://amzn.to/3qlKapp',
    category: 'Book',
    image: 'designasart.jpg',
    numBought: 4,
    content:
      "by Bruno Munari. \n Description: Bruno Munari was among the most inspirational designers of all time, described by Picasso as “the new Leonardo.” Munari insisted that design be beautiful, functional and accessible, and this enlightening and highly entertaining book sets out his ideas about visual, graphic and industrial design and the role it plays in the objects we use everyday. Lamps, road signs, typography, posters, children's books, advertising, cars and chairs—these are just some of the subjects to which he turns his illuminating gaze.",
  },
  {
    title: 'Standard Manual: New York City Transit Authority',
    price: 'N/A',
    link: 'https://amzn.to/3oJefP2',
    category: 'Book',
    image: 'nyctransit.jpg',
    numBought: 1,
    content: 'by Jesse Reed. \n Inspiration via MetroCard swipe. \n Description: The NYCTA Graphics Standards Manual contains scans of Massimo Vignelli and Bob Noorda’s (Unimark) modernist masterpiece. The manual describes the design and construction for the iconic NYC subway signs that we still see and use.',
  },
];