import { ButtonGroup, Container, IconButton, Stack, Text } from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
// import { Logo } from './Logo'

export const Footer = () => (
  <Container as="footer" role="contentinfo" py={{ base: '12', md: '12' }}>
    <Stack spacing={{ base: '4', md: '5' }}>
      <Stack justify="space-between" direction="row" align="center">
        {/* <Logo /> */}
        <Text fontSize="sm" color="fg.subtle">
        &copy; {new Date().getFullYear()} Justin Quan
      </Text>
        <ButtonGroup variant="tertiary">
          <IconButton
            as="a"
            href="https://www.linkedin.com/in/justquan/"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton as="a" href="https://www.youtube.com/channel/UCVyht-UPSsb-n_gRdbrA6bQ" aria-label="GitHub" icon={<FaYoutube fontSize="1.25rem" />} />
          <IconButton
            as="a"
            href="https://twitter.com/justoutquan"
            aria-label="Twitter"
            icon={<FaTwitter fontSize="1.25rem" />}
          />
        </ButtonGroup>
      </Stack>
      {/* <Text fontSize="sm" color="fg.subtle">
        &copy; {new Date().getFullYear()} Chakra UI Pro, Inc. All rights reserved.
      </Text> */}
    </Stack>
  </Container>
)