import React from 'react';
import {
  Button,
  Collapse,
  Box,
  Link,
  HStack,
  Text,
  Spacer,
  Tag,
  Stack,
  Badge,
  Image,
  VStack,
} from '@chakra-ui/react';
import {ExternalLinkIcon} from '@chakra-ui/icons';
import { useMediaQuery } from '@chakra-ui/react'

const Block = ({ title, content, price, link, category, numBought, image, order }) => {


  const renderText = () => {
    if (content == null) {
      return null;
    }
    const strArr = content.split('\n')
    return strArr.map((str, i) => (
      <>
        <Text marginBottom={[2, 4]} textAlign="left" fontSize={['sm', 'md']}>{str}</Text>

      </>
    ))

  }
  const [show, setShow] = React.useState(false);
  const [visibleBuy, setVisibleBuy] = React.useState(false);
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  })

  // const handleToggle = () => setShow(!show);
  function handleToggle () {
    setShow(!show)
    // setVisibleBuy(true)
  }

  function getCategoryColor() {
    switch (category){
      case 'Platform':
        return 'blue'
      case 'Book':
        return 'orange'
      default:
        return 'green'
    }
  }
  const categoryColor = getCategoryColor();

  return (
    <Box  p="4" borderBottomWidth={order==='last'? "1px" : "0px"} borderLeftWidth={"1px"}borderRightWidth={"1px"} borderTopWidth={"1px"} w={['95vw', '60vw']} borderTopEndRadius={order==="first" ? "md" : "0"}  borderBottomEndRadius={order==="last" ? "md" : "0"} onMouseEnter={() => setVisibleBuy(true)} onMouseLeave={() => setVisibleBuy(false)}   >
      <HStack onClick={handleToggle} spacing='12px'cursor={'pointer'} _hover={{opacity: .85}}>
        
        {isLargerThan800 ? 

        <>
        <Stack direction={['row']}>
          <Text fontWeight={550} width={'28vw'} textAlign={'left'} fontSize={['md', 'lg']} marginTop={1} marginBottom={1}>
              {title}
          </Text>
          {/* <Spacer/> */}
          <HStack spacing={3}>
            <Tag size={['sm', 'md']} borderRadius='full' variant='solid' colorScheme='green' flexShrink={0}flexGrow={0}>${price}</Tag>
            <Tag flexShrink={0} flexGrow={0} size={['sm', 'md']}  variant='solid' colorScheme={categoryColor} >{category}</Tag>
            <Tag flexShrink={0}  colorScheme='green'>{numBought || 7} Expensed</Tag>
          </HStack>
        </Stack>
        <Spacer/>
        {
          (isLargerThan800 && !visibleBuy && !show) ? <></> :
        <Link href={link} isExternal margin="-0.5">
          <Button flexShrink={0} size={['sm', 'md']} variantColor="blue" onClick={handleToggle} hidden={isLargerThan800 && (!visibleBuy && !show)}  p="3" margin="-1">
            Buy & Expense
          </Button>
        </Link>
        }
        </>

        :
        <>
        <Stack direction={'column'} w='100vw'>
          <HStack direction={'row'}>
            <Text textAlign={'left'} fontSize={['md', 'lg']} marginTop={1} marginBottom={1}>
                {title}
            </Text>
            <Spacer/>
            <Link justifySelf="flex-end" href={link} isExternal margin="-0.5" >
              <Button flexShrink={0} size={['sm', 'md']} variantColor="blue" onClick={handleToggle} hidden={isLargerThan800 && (!visibleBuy && !show)}  p="3" margin="-1">
                Expense
              </Button>
            </Link>
          </HStack>
          <HStack spacing={3}>
            <Tag size={['sm', 'md']} borderRadius='full' variant='solid' colorScheme='green' flexShrink={0}flexGrow={0}>${price}</Tag>
            <Tag flexShrink={0} flexGrow={0} size={['sm', 'md']}  variant='solid' colorScheme={categoryColor} >{category}</Tag>
            <Tag size={['sm', 'md']} flexShrink={0}  colorScheme='green'>{numBought || 7} Expensed</Tag>
          </HStack>
        </Stack>
        </>
        }


      </HStack>
      <Collapse mt={4} in={show} duration={1}>
        <Spacer h={[1, 4]}/>
        <Stack direction={['column', 'row']}>
          <VStack align={"left"}>
            {renderText()}
            <Text textAlign="left" fontSize={['sm', 'md']}>Price: ${price}</Text>
            <Link textAlign="left" fontSize={['sm', 'md']} href={link} isExternal margin="-0.5">Visit Page <ExternalLinkIcon mx='2px' /></Link>
          </VStack>
          <Spacer/>
          {image != null ? <Image borderRadius={['md', 'lg']} width={['250px', '420px']} src={require('../images/' + image)} />  : null }
        </Stack>
      </Collapse>
    </Box>
  );
};

export default Block;
