export const leadershipData = [
  {
    title: 'Skillshare 1-Year',
    price: 168,
    link: 'https://www.amazon.com',
    category: 'Platform',
    image: 'skillshare.png',
    numBought: 11,
    content:
      "No surprise, Skillshare has TONS of courses on leadership and management. \n While you're becoming a more effective leader, you can use the same plan to get better at cooking/photography/other things in life that you care about.",
  },
  {
    title: 'Coursera Plus 1-Year',
    price: 399,
    link: 'https://imp.i384100.net/AWKNrx',
    category: 'Platform',
    image: 'courseraleader.png',
    numBought: 10,
    content:
      "Similar to Skillshare: Coursera has tons of high-quality leadership courses, but the classes are more involved and can span weeks. Because of this, it's more $$$, and they come with more robust certifications, if you care about that sorta thing. \n Like Skillshare, you can use the same plan to get better at non-business-related life endeavors like film-making or baking.",
  },
  {
    title: 'Atomic Habits',
    price: 10.56,
    link: 'https://amzn.to/47jT32Q',
    category: 'Book',
    image: 'atomichabits.jpg',
    numBought: 15,
    content:
      "by James Clear. \n Description: James Clear, one of the world's leading experts on habit formation, reveals practical strategies that will teach you exactly how to form good habits, break bad ones, and master the tiny behaviors that lead to remarkable results.",
  },
  {
    title: 'High Growth Handbook: Scaling Startups From 10 to 10,000 People',
    price: 20,
    link: 'https://amzn.to/3NdHTFy',
    category: 'Book',
    image: 'highgrowth.jpg',
    numBought: 7,
    content:
      'by Elad Gil. \n Description: Global technology executive, serial entrepreneur, and angel investor Elad Gil has worked with high-growth tech companies including Airbnb, Twitter, Google, Stripe, and Square as they’ve grown from small companies into global enterprises. \n Across all of these breakout companies, Gil has identified a set of common patterns and created an accessible playbook for scaling high-growth startups, which he has now codified in High Growth Handbook',
  },
  {
    title: 'The 15 Commitments of Conscious Leadership',
    price: 15.3,
    link: 'https://amzn.to/3MTWKne',
    category: 'Book',
    image: '15commitments.jpg',
    numBought: 5,
    content:
      "by Jim Dethmer. \n You should still read it regardless, but if you don't want to, here are the 15. \n 1. Taking Radical Responsibility \n 2. Learning Through Curiosity \n 3. Feeling All Feelings \n 4. Speaking Candidly \n 5. Eliminating Gossip \n 6. Practicing Integrity \n 7. Generating Appreciation \n 8. Excelling in your Zone of Genius \n 9. Living a Life of Play and Rest \n 10. Exploring the Opposite \n 11. Sourcing Approval, Control and Security \n 12. Having Enough of Everything \n 13. Experiencing the World as an Ally \n 14. Creating Win for All Solutions \n 15. Being the Resolution",
  },
  {
    title: 'The Mastery of Love',
    price: 9.8,
    link: 'https://amzn.to/3C9wW1H',
    category: 'Book',
    image: 'masteryoflove.jpg',
    numBought: 3,
    content:
      "by Don Miguel Ruiz. \n A book that'll probably improve your relationship (or lackthereof), but also good for improving your leadership (probably)",
  },
  {
    title: 'Extreme Ownership: How U.S. Navy SEALs Lead and Win',
    price: 17.75,
    link: 'https://amzn.to/3MQDE1s',
    category: 'Book',
    image: 'extremeownership.jpg',
    numBought: 7,
    content: 'by Jocko Willink \n How to wake up at 5am every day',
  },
  {
    title: 'Principles: Life and Work',
    price: 18.98,
    link: 'https://amzn.to/45Fq5ue',
    category: 'Book',
    image: 'principlesog.jpg',
    numBought: 5,
    content:
      "by Ray Dalio \n Touted as 'required reading' by many business leaders. \n Description: In Principles, Dalio shares what he’s learned over the course of his remarkable career. He argues that life, management, economics, and investing can all be systemized into rules and understood like machines. The book’s hundreds of practical lessons, which are built around his cornerstones of “radical truth” and “radical transparency,” include Dalio laying out the most effective ways for individuals and organizations to make decisions, approach challenges, and build strong teams. He also describes the innovative tools the firm uses to bring an idea meritocracy to life, such as creating “baseball cards” for all employees that distill their strengths and weaknesses, and employing computerized decision-making systems to make believability-weighted decisions. While the book brims with novel ideas for organizations and institutions, Principles also offers a clear, straightforward approach to decision-making that Dalio believes anyone can apply, no matter what they’re seeking to achieve.",
  },
  {
    title: 'Principles for Dealing with the Changing World Order',
    price: 20.99,
    link: 'https://amzn.to/3IZq4Yy',
    category: 'Book',
    image: 'principles.jpg',
    numBought: 5,
    content:
      'by Ray Dalio \n Description: In Principles, Dalio shares what he’s learned over the course of his remarkable career. He argues that life, management, economics, and investing can all be systemized into rules and understood like machines. The book’s hundreds of practical lessons, which are built around his cornerstones of “radical truth” and “radical transparency,” include Dalio laying out the most effective ways for individuals and organizations to make decisions, approach challenges, and build strong teams. He also describes the innovative tools the firm uses to bring an idea meritocracy to life, such as creating “baseball cards” for all employees that distill their strengths and weaknesses, and employing computerized decision-making systems to make believability-weighted decisions. While the book brims with novel ideas for organizations and institutions, Principles also offers a clear, straightforward approach to decision-making that Dalio believes anyone can apply, no matter what they’re seeking to achieve.',
  },
  {
    title:
      'The Outsiders: Eight Unconventional CEOs and Their Radically Rational Blueprint for Success',
    price: 27.3,
    link: 'https://amzn.to/3WQircp',
    category: 'Book',
    image: 'outsiders.jpg',
    numBought: 6,
    content:
      'by William N. Thorndike Jr. \n Description: In this refreshing, counterintuitive book, author Will Thorndike brings to bear the analytical wisdom of a successful career in investing, closely evaluating the performance of companies and their leaders. You will meet eight individualistic CEOs whose firms’ average returns outperformed the S&P 500 by a factor of twentyin other words, an investment of $10,000 with each of these CEOs, on average, would have been worth over $1.5 million twenty-five years later. You may not know all their names, but you will recognize their companies: General Cinema, Ralston Purina, The Washington Post Company, Berkshire Hathaway, General Dynamics, Capital Cities Broadcasting, TCI, and Teledyne. In The Outsiders, you’ll learn the traits and methods striking for their consistency and relentless rationalitythat helped these unique leaders achieve such exceptional performance.',
  },
  {
    title: 'Grit',
    price: 14.87,
    link: 'https://amzn.to/43ImDx7',
    category: 'Book',
    image: 'grit.jpg',
    numBought: 6,
    content:
      'by Angela Duckworth. \n Build grit. Duckworth gave a viral Ted Talk that I still remember watching in middle school: https://www.youtube.com/watch?v=H14bBuluwB8',
  },
  {
    title: 'Give and Take',
    price: 9.99,
    link: 'https://amzn.to/3IX3b7P',
    category: 'Book',
    image: 'giveandtake.jpg',
    numBought: 8,
    content:
      'by Adam Grant. \n Description: In Give and Take, Adam Grant, an award-winning researcher and Wharton’s highest-rated professor, examines the surprising forces that shape why some people rise to the top of the success ladder while others sink to the bottom. ',
  },
  {
    title: 'Outliers: The Story of Success',
    price: 33,
    link: 'https://amzn.to/43rlg6k',
    category: 'Book',
    image: 'outliers2.jpg',
    numBought: 5,
    content:
      'by Malcom Gladwell. \n The Amazon description reads "[Gladewell] explains the secrets of software billionaires, what it takes to be a great soccer player, why Asians are good at math, and what made the Beatles the greatest rock band." LMAO',
  },
  {
    title: 'The Hard Thing About Hard Things',
    content:
      "A consolidation of Ben Horowitz's blogs about how to run a startup. \n Description: Ben Horowitz, cofounder of Andreessen Horowitz and one of Silicon Valley's most respected and experienced entrepreneurs, offers essential advice on building and running a startup—practical wisdom for managing the toughest problems business school doesn’t cover, based on his popular ben’s blog.",
    price: '17.89',
    link: 'https://amzn.to/438RjrB',
    category: 'Book',
    image: 'hardthing.jpg',
    numBought: 4,
  },
  {
    title: 'How to Win Friends & Influence People',
    price: 19.98,
    link: 'https://amzn.to/43KGWu6',
    category: 'Book',
    image: 'winfriends.jpg',
    numBought: 11,
    content: 'by Dale Carnegie. \n The OG',
  },
];