export const sweData = [
  {
    title: 'Skillshare 1-Year',
    content:
      "No surprise, Skillshare has TONS of courses on software engineering. There's one for every JS framework, backend architecture, and infra service out there.\n While you're becoming a React/Go/Kubernetes legend, you can use the same plan to get better at cooking/photography/other things in life that you care about.",
    price: '168',
    link: 'https://skillshare.eqcm.net/c/4273707/298081/4650',
    category: 'Platform',
    image: 'skillshare.png',
    numBought: 11,
  },
  {
    title: 'Coursera Plus 1-Year',
    content:
      "Similar to Skillshare: Coursera has tons of high-quality software courses, but the classes are more involved and can span weeks. Because of this, it's more $$$, and they come with more robust certifications, if you care about that sorta thing. \n Like Skillshare, you can use the same plan to get better at non-Kubernetes-related life endeavors like film-making or baking.",
    price: '399',
    link: 'https://imp.i384100.net/AWKNrx', //5/30/23 affiliate link to plus landing page
    category: 'Platform',
    image: 'coursera.jpg',
    numBought: 10,
  },
  {
    title: 'Udemy Personal Plan (Monthly)',
    content:
      "Udemy's kinda between Skillshare and Coursera. They have an umbrella monthly plan that includes 8,000 courses, but not every course on the platform. You can opt to expense indvidual classes, which have a flat price hovering between $12 to $200.",
    price: '16.58',
    link: 'https://www.udemy.com/personal-plan/',
    category: 'Platform',
    image: 'udemyswe.png',
    numBought: 4,
  },
  {
    title: "O'Reilly Online Learning 1-Year",
    content:
      "Get access to some seemingly jurassic-period (but iconic) textbooks on software engineering. \nO'Reilly's less sexy than some of these other platforms, but they're among the popularly read programming resources in the wolrd. If you expense an O'Reilly license, it'll turn you into a GOAT software engineer—or at least make you look like one.",
    price: '499',
    link: 'https://www.oreilly.com/online-learning/pricing.html',
    category: 'Platform',
    image: 'oreilly.jpg',
    numBought: 2,
  },
  {
    title: 'Codeacademy 1-Year Membership',
    content:
      "Believe it or not, Codecademy isn't only for your 8-year old cousin learning Python for the first time; they've updated their courses in the last few years to have more thought-out and complex courses. It's a pretty underrated way to spend a day learning Mongo DB, basic ML, or iOS app development",
    price: '359.88',
    link: 'https://www.amazon.com/',
    category: 'Platform',
    image: 'codeacademy.jpg',
    numBought: 4,
  },
  {
    title: 'Software Engineering, 10th Edition',
    content:
      'A big-ass and expensive book on software engineering—the tenth one. \n Description: Software Engineering introduces readers to the overwhelmingly important subject of software programming and development. In the past few years, computer systems have come to dominate not just our technological growth, but the foundations of our world’s major industries. This text seeks to lay out the fundamental concepts of this huge and continually growing subject area in a clear and comprehensive manner.',
    price: '89.16',
    link: 'https://amzn.to/4am7LbQ',
    category: 'Book',
    image: 'softwareengineeringbook.jpg',
    numBought: 5,
  },
  {
    title: 'Zero to One',
    content:
      'The Peter Thiel Bible \n Description: The great secret of our time is that there are still uncharted frontiers to explore and new inventions to create. In Zero to One, legendary entrepreneur and investor Peter Thiel shows how we can find singular ways to create those new things. \n Thiel begins with the contrarian premise that we live in an age of technological stagnation, even if we’re too distracted by shiny mobile devices to notice. Information technology has improved rapidly, but there is no reason why progress should be limited to computers or Silicon Valley. Progress can be achieved in any industry or area of business. It comes from the most important skill that every leader must master: learning to think for yourself.',
    price: '22.17',
    link: 'https://amzn.to/3C1KcoO',
    category: 'Book',
    image: 'zerotoone.jpg',
    numBought: 7,
  },
  {
    title: 'Cracking the Coding Interview',
    content:
      "Don't worry, you're not thinking of jumping ship, but it doesn't hurt to brush up on some of these questions... \n Description: Cracking the Coding Interview, 6th Edition is here to help you through this process, teaching you what you need to know and enabling you to perform at your very best. [The author has] coached and interviewed hundreds of software engineers. The result is this book.",
    price: '29.93',
    link: 'https://amzn.to/3qfjt5G',
    category: 'Book',
    image: 'ctci.jpg',
    numBought: 9,
  },
  {
    title: 'Clean Code',
    content:
      "If your manager sees this on your desk, there's no way you're not getting a promo in your next review cycle. An old but actual legendary book among programmers—as one Amazon review noted, 'Knowledge in This Tome is the Demarcation Between Programmers and Engineers.' \n Description: Even bad code can function. But if code isn’t clean, it can bring a development organization to its knees. Every year, countless hours and significant resources are lost because of poorly written code. But it doesn’t have to be that way. Noted software expert Robert C. Martin, presents a revolutionary paradigm with Clean Code: A Handbook of Agile Software Craftsmanship. Martin, who has helped bring agile principles from a practitioner’s point of view to tens of thousands of programmers, has teamed up with his colleagues from Object Mentor to distill their best agile practice of cleaning code “on the fly” into a book that will instill within you the values of software craftsman, and make you a better programmer―but only if you work at it.",
    price: '47.49',
    link: 'https://amzn.to/3MEU8JT',
    category: 'Book',
    image: 'cleancode.jpg',
    numBought: 6,
  },
];